<template>

    <div class="dashboard-layout">

        <nav class=" main-nav uk-navbar-container uk-margin" uk-navbar>
            <div class="nav-inner-container uk-container-large" style="margin: auto;">
                <div class="uk-navbar-left">

                    <a class="uk-navbar-item uk-logo" href="#">
                        <img
                            style="height: 60px; width: 60px;stroke:red; fill:blue"
                            :src="getDynamicAssetUrl('images/logos/main.svg')" />
                    </a>

                    <ul class="uk-navbar-nav">
                        <li>
                            <a href="https://nextchats.com/">
                                {{translate('nextchat.mainNav.backHome')}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </nav>

        <div class="main-content uk-container-large uk-margin-auto uk-margin-large-top">
            <slot></slot>
        </div>
        <div class="page-background" :style="containerImageStyle"></div>
    </div>



</template>

<script>

    export default {
        props: {
            backgroundImage: {
                type: String,
                default: 'page-bg-texture.png',
            }
        },
        components: {

        },
        data () {
            return {

            }
        },
        computed: {
            containerImageStyle () {
                return {};

                /*
                let url = utilities.requireAsset('images/backgrounds/' + this.backgroundImage);
                return {
                    backgroundImage : "url('"+url+"')",
                    opacity: this.backgroundOpacity + '!important'
                };

                 */
            },
        }
    }
</script>

<style lang="scss" scoped >
.nav-inner-container {
    width: 100%;
    max-width: 1400px;
}

.dashboard-layout {
    z-index: 1;
    position: relative;
}
.page-background  {
    z-index: 0;
    position: fixed;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    opacity: 0.3;
}
.main-nav {
    z-index: 1;
    position: relative;
}
.main-content {
    z-index: 1;
    position: relative;
}


</style>
